import { useEffect } from 'react';
import { capitalizeFirstLetter } from '@knapsack/utils';
import { useSelector, useAppCtxSelector, sendUiEvent } from '@/core';
import { DEFAULT_FAVICON_URL } from '../constants';

export function useKsMetaTags(
  {
    title,
    description,
  }: {
    title?: string | string[];
    description?: string;
  } = {
    title: '',
    description: '',
  },
): void {
  const siteId = useAppCtxSelector(({ site }) => site?.meta.siteId);
  const siteTitle = useSelector((s) => s.db?.settings?.title);
  const favicon = useSelector(
    (s) => s.db.settings?.favicon || DEFAULT_FAVICON_URL,
  );

  const fullTitle = [
    ...(Array.isArray(title) ? title : [title]),
    siteTitle || siteId,
    'Knapsack',
  ]
    .filter(Boolean)
    .map(capitalizeFirstLetter)
    .join(' | ');

  useEffect(() => {
    sendUiEvent({
      type: 'headTags.set',
      headTags: {
        title: fullTitle,
        description,
        favicon,
      },
    });
  }, [fullTitle, favicon, description]);
}
