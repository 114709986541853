import { FloatingAction } from '@knapsack/toby';

const SupportChatTrigger = () => {
  return (
    <FloatingAction
      icon="chat"
      label="Contact Knapsack"
      onTrigger={async () => {
        const { showKnapsackFeedbackModal } = await import(
          '@/core/modals/knapsack-support-modal'
        );
        showKnapsackFeedbackModal();
      }}
      placement="bottom-right"
      tooltipPlacement="left"
      testId="supportChatTrigger"
      zIndex={100}
    />
  );
};

export default SupportChatTrigger;
