'use client';

import { useRef, useState } from 'react';
import {
  sendAppEvent,
  sendUiEvent,
  useAppCtxSelector,
  useUserRole,
  useUserRoleCanEdit,
} from '@/core';
import { Avatar, Menu, MenuIcon, MenuItem, MenuText } from '@knapsack/toby';
import { useKsNav } from '@/utils/routes';
import SuperAdminControls from '@/core/super-admin-controls';

export const AccountMenu = () => {
  // Grab user and site off of xstate context
  const ctx = useAppCtxSelector(
    ({ user, site }) => ({
      user,
      site,
    }),
    { isEqualityDeep: true },
  );

  const userRoleCanEdit = useUserRoleCanEdit();

  const { goToRoot, goToSetting, isSiteInstanceReady } = useKsNav();
  const isViewer = useUserRole() === 'VIEWER';

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Avatar
        size="small"
        alt={ctx.user?.info?.displayName}
        imgSrc={ctx.user?.info?.profilePic}
        onTrigger={() => setIsMenuOpen(!isMenuOpen)}
        ref={anchorRef}
        testId="account-menu--trigger"
        label="User account menu"
      />
      <Menu
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        header={
          ctx.user?.isSuperAdmin && (
            <MenuItem
              onTrigger={() => {
                setIsMenuOpen(false);
                sendUiEvent([
                  {
                    type: 'modal.setContent',
                    modal: {
                      body: <SuperAdminControls />,
                      testId: 'super-admin-controls',
                      title: 'Super Admin Controls',
                    },
                  },
                  'modal.triggerOpen',
                ]);
              }}
            >
              <MenuIcon symbol="state-warning" />
              <MenuText>Super admin</MenuText>
            </MenuItem>
          )
        }
        footer={
          <MenuItem
            onTrigger={() => sendAppEvent('user.signOut')}
            testId="account-menu--logout"
          >
            <MenuText>Log out</MenuText>
          </MenuItem>
        }
      >
        {ctx.site?.meta.siteId && (
          <MenuItem
            onTrigger={() => {
              setIsMenuOpen(false);
              import('@/domains/users/components/user-invite').then(
                ({ showUserInviteModal }) =>
                  showUserInviteModal({ testId: 'user-menu--invite' }),
              );
            }}
          >
            <MenuIcon symbol="send" />
            <MenuText>Invite someone</MenuText>
          </MenuItem>
        )}
        <MenuItem
          onTrigger={() => {
            setIsMenuOpen(false);

            // Scenario 1: user is on site instance
            if (isSiteInstanceReady) {
              userRoleCanEdit
                ? // contributor+ should see profile within admin settings
                  goToSetting({ settingId: 'profile' })
                : // Non-contributor+ should see profile within root user settings
                  goToRoot('/user');
            } else {
              // Scenario 2: user is on root /
              goToRoot('/user');
            }
          }}
        >
          <MenuIcon symbol="user-circle" />
          <MenuText>Profile settings</MenuText>
        </MenuItem>
      </Menu>
    </>
  );
};
