import React, { useState } from 'react';
import { isRoleValid } from '@knapsack/core';
import { type Role, rolesById } from '@knapsack/types';
import {
  FieldToggle,
  FieldTextInput,
  FieldSelect,
  ButtonPrimary,
  FieldNumberInput,
  Accordion,
  componentSpacing,
} from '@knapsack/toby';
import { entries } from '@knapsack/utils';
import { Controller, useForm } from 'react-hook-form';
import {
  featureFlags as oldFeatureFlags,
  setDevFeatureFlags,
} from '@/utils/feature-flags';
import { sendAppEvent, useAppCtxSelector } from '.';
import {
  getUserRoleOverride,
  removeUserRoleOverride,
  setUserRoleOverride,
} from './utils/user-override-utils';

const { enableStateInspector, appApiGqlEndpoint } = oldFeatureFlags;

const roleItems = entries(rolesById).map(([key, value]) => ({
  value,
  label: value,
}));

type FormValues = {
  role: '' | Role;
};

const RoleOverideForm = () => {
  const { control, handleSubmit, reset } = useForm<
    FormValues,
    never,
    FormValues
  >({
    defaultValues: {
      role: getUserRoleOverride(),
    },
  });
  return (
    <form
      onSubmit={handleSubmit(({ role }) => {
        if (!role) {
          removeUserRoleOverride();
        } else if (isRoleValid(role)) {
          setUserRoleOverride(role);
        }
        setTimeout(() => {
          window.location.reload();
        }, 250);
      })}
    >
      <Controller
        control={control}
        name="role"
        render={({ field: { onChange, value, ref } }) => (
          <FieldSelect
            onChange={onChange}
            value={value as Role}
            ref={ref}
            placeholder="(None)"
            label="Role Override"
            helperText="Overrides the current user's role for testing purposes for just this session. Open new tab to reset your role."
            options={roleItems}
          />
        )}
      />
      <ButtonPrimary
        type="submit"
        onTrigger={() => {}}
        label="Set Role Override"
      />
    </form>
  );
};

const SuperAdminControls: React.VFC = () => {
  const flags = useAppCtxSelector(({ featureFlags }) => featureFlags);
  const [url, setUrl] = useState(appApiGqlEndpoint || '');

  // Sorts the feature flags so that strings and numbers come first
  const sortableFlags = Object.entries(flags).sort((a) =>
    typeof a[1] === 'string' || typeof a[1] === 'number' ? -1 : 1,
  );

  return (
    <div
      className={componentSpacing({
        paddingBottom: 'large',
        paddingTop: 'small',
      })}
    >
      <Accordion
        initiallyOpenIds={['dev-toggles']}
        items={[
          {
            id: 'dev-toggles',
            title: 'Dev Toggles',
            content: (
              <div className={componentSpacing({ padding: 'large' })}>
                <RoleOverideForm />
                <FieldTextInput
                  label="App API GraphQL Endpoint"
                  helperText="Sets value onBlur"
                  helperDisplay="caption"
                  labelPosition="top"
                  value={url}
                  onChange={setUrl}
                  onBlur={(e) => {
                    setDevFeatureFlags({
                      appApiGqlEndpoint: e.target.value,
                    });
                  }}
                />
                <FieldToggle
                  value={enableStateInspector}
                  label="Enable State Chart"
                  onChange={(value) => {
                    setDevFeatureFlags({
                      enableStateInspector: value,
                    });
                  }}
                />
              </div>
            ),
          },
          {
            id: 'feature-flags',
            title: 'Feature Flags',
            content: (
              <div className={componentSpacing({ padding: 'large' })}>
                {sortableFlags.map(([flag, value]) => {
                  switch (typeof value) {
                    case 'boolean':
                      return (
                        <div key={flag}>
                          <FieldToggle
                            value={value}
                            label={flag}
                            onChange={(newValue) =>
                              sendAppEvent({
                                type: 'featureFlags.changed',
                                flags: {
                                  [flag]: newValue,
                                },
                              })
                            }
                          />
                        </div>
                      );
                    case 'string':
                      return (
                        <div key={flag}>
                          <FieldTextInput
                            label={flag}
                            value={value}
                            onChange={(newValue) =>
                              sendAppEvent({
                                type: 'featureFlags.changed',
                                flags: {
                                  [flag]: newValue,
                                },
                              })
                            }
                          />
                        </div>
                      );
                    case 'number':
                      return (
                        <div key={flag}>
                          <FieldNumberInput
                            label={flag}
                            value={value}
                            onChange={(newValue) => {
                              if (newValue > 0) {
                                sendAppEvent({
                                  type: 'featureFlags.changed',
                                  flags: {
                                    [flag]: newValue,
                                  },
                                });
                              }
                            }}
                          />
                        </div>
                      );
                    default:
                      return (
                        <div key={flag}>
                          The flag <code>{flag}</code> is type {typeof value}{' '}
                          and we do not have a form field for it.
                        </div>
                      );
                  }
                })}
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default SuperAdminControls;
