export function isValidUrl(url: string): boolean {
  try {
    const x = new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

export function isSameBaseUrl(a: string, b: string): boolean {
  try {
    return new URL(a).origin === new URL(b).origin;
  } catch (e) {
    return false;
  }
}

export function getGithubRepoInfo(repoUrl: string): {
  owner: string;
  repoName: string;
} {
  if (!repoUrl.includes('https://github.com')) {
    throw new Error(`This does not appear to be a github url: "${repoUrl}"`);
  }
  const [owner, repoName] = repoUrl
    .replace('https://github.com/', '')
    .split('/');
  return { owner, repoName };
}

export function openUrlInTab(url: string): void {
  window.open(url, '_blank').focus();
}

/**
 * Get the full path of the current URL w/o origin.
 * Returns something like: `/some/path?some=query#some-hash`
 * Does NOT include the origin.
 * Does include both query string and hash.
 * Problems this works around:
 * ```js
 * const u = new URL(window.location);
 * // does not include query string or hash
 * u.pathname;
 * // has origin
 * u.href;
 * // does not include hash
 * u.pathname + u.search;
 * // too cumbersome and easy to forget but works
 * u.pathname + u.search + u.hash;
 * ```
 */
export function getUrlFullPath() {
  if (!window) {
    throw new Error('"window" is not defined');
  }
  return window.location.href.replace(window.location.origin, '');
}
