import React, { Suspense, lazy } from 'react';
import { TextBody, TextTitle } from '@knapsack/toby';
import { useKsMetaTags } from '@/utils/hooks/useKsMetaTags';
import { useTrackPageTypeView } from '@/utils/analytics';
import { ErrorCatcher } from '@/utils/error-catcher';
import {
  pageStyles,
  pageContent,
  PageStyleVariants,
  pageFooter,
  pageHeader,
} from './page-layout.css';

const PageLockedMessage = lazy(() => import('./page-layout.locked-message'));

interface PageBase {
  children: React.ReactNode;
  footer?: React.ReactNode;
  footerFixedToBottom?: boolean;
  size?: PageStyleVariants['size'];
  testId?: string;
  locked?: boolean;
  removePadding?: boolean;
  trackInfo: Parameters<typeof useTrackPageTypeView>[0];
  metaTags: Parameters<typeof useKsMetaTags>[0];
  pageHasTabs?: boolean;
}

interface PageWithHeader extends PageBase {
  title: string;
  description?: string;
  hero?: never;
}

interface PageWithHero extends PageBase {
  hero?: React.ReactNode;
  title?: never;
}

export type PageProps = PageWithHeader | PageWithHero;

const isPageWithHeader = (props: PageProps): props is PageWithHeader =>
  'title' in props;

const isPageWithHero = (props: PageProps): props is PageWithHero =>
  'hero' in props;

export const PageLayout = (props: PageProps) => {
  const {
    children,
    footer,
    size,
    testId,
    locked = false,
    footerFixedToBottom = false,
    removePadding = false,
    trackInfo,
    metaTags,
    pageHasTabs = false,
  } = props;
  useKsMetaTags(metaTags);
  useTrackPageTypeView(trackInfo);

  return (
    <article className={pageStyles({ size })} data-test-id={testId}>
      {locked ? (
        <PageLockedMessage />
      ) : (
        <>
          {isPageWithHeader(props) && (
            <header className={pageHeader} data-test-id="page-header">
              <TextTitle size="small" spacing="none">
                {props.title}
              </TextTitle>
              {props.description && <TextBody>{props.description}</TextBody>}
            </header>
          )}
          {isPageWithHero(props) && props.hero}
          <section className={pageContent({ pageHasTabs, removePadding })}>
            <ErrorCatcher>
              <Suspense>{children}</Suspense>
            </ErrorCatcher>
          </section>
        </>
      )}
      {footer && (
        <footer className={pageFooter({ fixedToBottom: footerFixedToBottom })}>
          {footer}
        </footer>
      )}
    </article>
  );
};
