'use client';

import React from 'react';
import { Banner, SuspenseLoader, createToast } from '@knapsack/toby';
import * as Sentry from '@sentry/nextjs';

export * from 'react-error-boundary';

type Props = {
  children: React.ReactNode;
  /**
   * Show Banner or Toast
   */
  showToast?: boolean;
};

// https://docs.sentry.io/platforms/javascript/guides/react/enriching-events/user-feedback/#customizing-the-widget
export const ErrorCatcher = ({ children, showToast }: Props) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) =>
        showToast ? null : (
          <Banner
            type="error"
            title={
              error instanceof Error ? error.message : JSON.stringify(error)
            }
          />
        )
      }
      showDialog={process.env.NEXT_PUBLIC_ENV === 'production'}
      onError={(error) => {
        if (showToast) {
          createToast({
            title: 'Error',
            message:
              error instanceof Error ? error.message : JSON.stringify(error),
            type: 'error',
            action: {
              label: 'Reload',
              onTrigger: () => window.location.reload(),
            },
          });
          return;
        }
        if (!(error instanceof Error)) return;
        const reloadedKey = 'reloadedFromChunkError';
        const alreadyReloaded =
          window.sessionStorage.getItem(reloadedKey) === 'yes';
        if (error.name === 'ChunkLoadError' && !alreadyReloaded) {
          window.sessionStorage.setItem(reloadedKey, 'yes');
          window.location.reload();
        }

        const targetErrorReloadKey = 'targetErrorReload';
        const alreadyTargetErrorReloaded =
          window.sessionStorage.getItem(targetErrorReloadKey) === 'yes';
        if (
          error.message.includes('Expected to find valid target') &&
          !alreadyTargetErrorReloaded
        ) {
          window.sessionStorage.setItem(targetErrorReloadKey, 'yes');
          window.location.reload();
        }
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export const SuspenseAndErrorCatcher = ({
  children,
  fallback,
  delay,
  forceFallback,
}: {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  delay?: number;
  /**
   * Just show fallback - useful for designing
   */
  forceFallback?: boolean;
}) => {
  return (
    <SuspenseLoader
      delay={delay}
      fallback={fallback}
      forceFallback={forceFallback}
    >
      <ErrorCatcher>{children}</ErrorCatcher>
    </SuspenseLoader>
  );
};
