import { KnapsackWordmark } from '@/components/knapsack-logo/knapsack-logo';
import { AccountMenu } from '@/domains/users/components/account-menu';
import { appHeader } from './app-header.css';

export const AppHeader = (
  <header className={appHeader} data-test-id="app-header">
    <a href="/">
      <KnapsackWordmark color="subtle" />
    </a>
    <AccountMenu />
  </header>
);
