'use client';

import { useEffect } from 'react';
import { hasAuth0CallbackParams, loginWithRedirect } from '@/services/auth0';
import { useAppStateMatches } from '@/core';
import { useSearchParams } from 'next/navigation';

export const useIsAuth0AuthCallback = () => {
  const params = useSearchParams();
  return hasAuth0CallbackParams(params);
};

/**
 * React hook to login if we land on a page that we are not yet authorized to view.
 * Takes into account if callback params exist (code & state) so avoid trying to log
 * in repeatedly in those milliseconds between being redirected back from auth0
 * and actually signing in.
 */
export const useLoginCallbackIfNoParams = () => {
  const isLoggedIn = useAppStateMatches('user.loggedIn');
  const isAuth0Callback = useIsAuth0AuthCallback();
  useEffect(() => {
    // If we get sent back to the app from auth0, we'll have params necessary
    // to redirect us to our properly authenticated url. If these params exist
    // DO NOT loginWithRedirect()
    let id: NodeJS.Timeout;
    if (!isAuth0Callback && !isLoggedIn) {
      id = setTimeout(loginWithRedirect, 1000);
    }
    return () => clearTimeout(id);
  }, [isAuth0Callback, isLoggedIn]);
};
