'use client';

import { canRoleEdit } from '@knapsack/core';
import type { Role } from '@knapsack/types';
import { shallowEqual } from '@xstate/react';
import { useRef, useState } from 'react';
import {
  AppContext,
  appService,
  useAppCtxSelector,
  useAppStateMatches,
  useUiCtxSelector,
} from './machines/app/app.xstate';
import { AppState } from './machines/app/app.xstate-utils';
import { useIsomorphicLayoutEffect } from './xstate.utils';
import { extractPossibleInstanceIdFromSite } from '../env-and-content-src/utils';

interface AppStateSummary {
  isSiteLoaded: boolean;
  isPluginsLoaded: boolean;
  isLoggedIn: boolean;
  canEdit: boolean;
  userRole: Role;
  siteId?: string;
  userId?: string;
  isUserStateKnown: boolean;
  userCanEdit: boolean;
}

function getAppStateSummary(state: AppState): AppStateSummary {
  const { site, user } = state.context;
  return {
    isSiteLoaded: state.matches('site.loaded'),
    isPluginsLoaded: state.matches('site.loaded.plugins.loaded'),
    isLoggedIn: state.matches('user.loggedIn'),
    canEdit: state.matches('site.loaded.appClient.viewable.editable'),
    isUserStateKnown: !state.matches('user.unknown'),
    userRole: user?.getSiteRole?.(site?.meta.siteId) ?? 'ANONYMOUS',
    userCanEdit: canRoleEdit(user?.getSiteRole?.(site?.meta.siteId)),
    userId: user?.userId,
    siteId: site?.meta.siteId,
  };
}

/**
 * @deprecated Use individual hooks instead
 * @example
 * const isSiteLoaded = useAppStateMatches('site.loaded');
 * const isLoggedIn = useAppStateMatches('user.loggedIn');
 * const canEdit = useAppStateMatches('site.loaded.appClient.viewable.editable');
 * const siteId = useAppCtxSelector((ctx) => ctx.site?.meta.siteId);
 * const userRole = useAppCtxSelector((ctx => ctx.user?.getSiteRole?.(siteId) ?? Role.Anonymous));
 */
export function useAppStateSummary(): AppStateSummary {
  const lastSummary = useRef(getAppStateSummary(appService.state));
  const [appStateSummary, setAppStateSummary] = useState(lastSummary.current);

  useIsomorphicLayoutEffect(() => {
    const { unsubscribe } = appService.subscribe((state) => {
      // `state.changed` = when the state.value changes, the state.context changes, or there are new state.actions
      if (!state.changed) return;
      const nextSummary = getAppStateSummary(state);
      if (
        !lastSummary.current ||
        !shallowEqual(nextSummary, lastSummary.current)
      ) {
        lastSummary.current = nextSummary;
        setAppStateSummary(nextSummary);
      }
    });
    return unsubscribe;
  }, []);

  return appStateSummary;
}

export function useAppCanEdit(): boolean {
  return useAppStateMatches('site.loaded.appClient.viewable.editable');
}

export function useUserRole(): Role {
  return useAppCtxSelector(({ site, user }) => {
    return user?.getSiteRole(site?.meta.siteId) ?? 'ANONYMOUS';
  });
}

export function useUserRoleCanEdit(): boolean {
  return useAppCtxSelector(({ site, user }) => {
    return canRoleEdit(user?.getSiteRole(site?.meta.siteId));
  });
}

export function useAppAndUserCanEdit(): boolean {
  const appCanEdit = useAppCanEdit();
  const userRoleCanEdit = useUserRoleCanEdit();

  return appCanEdit && userRoleCanEdit;
}

/**
 * Checks if App Client is connected locally by one of two ways:
 * 1. `site.type === 'local'` - the classic local dev mode
 * 2. If the "Env" is "development" - i.e. the App Client URL is localhost
 */
export function useIsEnvDev(): boolean {
  return useAppCtxSelector(({ site }) => site?.env?.type === 'development');
}

export function useIsSitePrivate(): boolean {
  return useAppCtxSelector(({ site }) => site?.meta.isPrivate);
}

export function useInstanceId(): string | null {
  return useAppCtxSelector((ctx) =>
    extractPossibleInstanceIdFromSite(ctx.site),
  );
}

/**
 * Simplified check for winWidthName to not have to check
 * for large and xlarge.
 */
export function useIsLargeScreen(): boolean {
  return useUiCtxSelector(({ winWidthName }) => {
    return winWidthName === 'large' || winWidthName === 'xlarge';
  });
}

/**
 * Simplified check for winWidthName to not have to check
 * for xsmall and small
 */
export function useIsSmallScreen(): boolean {
  return useUiCtxSelector(({ winWidthName }) => {
    return winWidthName === 'xsmall' || winWidthName === 'small';
  });
}

/**
 * Grab a single feature flag. Example:
 * ```js
 * const enableWorldPeace = useFeatureFlag('enableWorldPeace');
 * ```
 */
export function useFeatureFlag<Flag extends keyof AppContext['featureFlags']>(
  flag: Flag,
  // generic ensures return type is accurate for what flag we are grabbing
): AppContext['featureFlags'][Flag] {
  return useAppCtxSelector((ctx) => ctx.featureFlags[flag]);
}
