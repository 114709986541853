import { forwardRef, PropsWithChildren } from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import { linkStyle, StyleVariants } from './text-link.css';

type TextLinkProps = StyleVariants['linkStyle'] &
  PropsWithChildren & {
    className?: string;
    href: string;
    testId?: string;
    external?: boolean;
  };

export const TextLink = forwardRef<HTMLAnchorElement, TextLinkProps>(
  function TextLinkInner(
    {
      href,
      children,
      className,
      color = 'default',
      external,
      testId,
      underline = false,
      weight = 'bolder',
    },
    ref,
  ) {
    return (
      <Link
        ref={ref}
        href={href}
        className={clsx([className, linkStyle({ color, underline, weight })])}
        data-test-id={testId}
        target={external ? '_blank' : ''}
        rel={external ? 'noreferrer' : ''}
      >
        {children}
      </Link>
    );
  },
);
